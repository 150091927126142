header-menu-dropdown {
  @extend .header-dropdown;
  display: flex;
  height: 500px;
  width: 1032px;

  &:after {
    @extend .header-dropdown-arrow;
    left: 255px;
    width: spacer(5);
    height: 23px;
    top: calc(#{spacer(5)} / -2);
    border-top-left-radius: spacer(2);
    background-color: var(--header-mega-dropdown-background-color);
  }

  .-left-pane {
    width: 250px;
    display: grid;
    grid-gap: spacer(4);
    grid-auto-rows: min-content;
    padding: spacer(6) spacer(30);
    background-color: var(--header-mega-dropdown-background-color);
    border-top-left-radius: spacer(2);
    border-bottom-left-radius: spacer(2);

    h3 {
      margin: 0;
      line-height: spacer(30);
      font-weight: 800;
      color: var(--header-mega-dropdown-left-pane-heading-color);
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    span.-left-pane-link app-link a {
      font-weight: 700;
      color: var(--link-color);
      &:hover {
        color: var(--link-hover-color);
      }
    }
  }

  .-right-pane {
    flex: 1;
    z-index: 1;
    padding: spacer(6) spacer(30);
    background-color: var(--header-mega-dropdown-background-color);
    border-left: 1px solid var(--header-mega-dropdown-right-pane-left-border-color);
    border-top-right-radius: spacer(2);
    border-bottom-right-radius: spacer(2);
    h4 {
      font-size: 16px;
      font-weight: 700;
      color: $colorWpPrimaryHeadTextColor;
      margin-bottom: spacer(5);
      text-transform: uppercase;
    }
    a {
      color: var(--link-color) !important;
      line-height: 26px;

      &:hover {
        color: var(--link-hover-color) !important;
        text-decoration: none;
      }
    }

    .-no-tab-container {
      display: flex;
      flex-direction: row;
    }
  }
}
