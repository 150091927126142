app-functional-nav {
  height: inherit;
  background-color: #001c25;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  order: 2;
  margin-right: spacer(2);
  z-index: 5;

  .icon-nav {
    &.-container {
      margin: 0;
      padding: 0;
      display: flex;
      align-self: center;
      list-style: none;
      flex-direction: row;
      wpbutton {
        margin: unset;
      }

      &.-item {
        position: relative;
        display: flex;
        height: 70px;
        align-items: center;
        margin-right: spacer(30);
        .-navIcon,
        a {
          svg {
            use {
              fill: var(--functional-nav-menu-item-color);
            }
          }
          &:hover {
            svg {
              use {
                fill: var(--functional-nav-menu-item-hover-color);
              }
            }
          }
        }
      }

      a {
        &:hover {
          svg {
            use {
              fill: var(--functional-nav-menu-item-hover-color);
            }
          }
        }
      }

      &.-user-account {
        margin-right: 0;
      }

      &.-loginLink {
        margin-right: 0;
        font-weight: 900;
        &:hover {
          color: var(--functional-nav-login-item-color-hover);
          text-decoration: none;
        }
      }
    }
    &.-navIcon {
      &:hover {
        .-dropdown {
          @extend .header-dropdown-transition-hover;
        }
        &:after {
          content: '';
          position: absolute;
          top: 63%;
          height: 5rem;
          width: 4rem;
          right: -33%;
        }

        .-help-dropdown {
          @extend .header-dropdown-transition-hover;
        }
      }
    }
    &.-dropdown {
      @extend .header-dropdown-transition;
      top: 95%;
      right: -55%;
    }
    &.-help-dropdown {
      @extend .header-dropdown-transition;
      top: 95%;
      right: -50%;
    }
  }
}
.-mobile-dropdown-hide {
  opacity: 0;
  visibility: hidden;
  top: 95%;
  right: -55%;
}

.-help-menu-dropdown-hide {
  opacity: 0;
  visibility: hidden;
  top: 95%;
  right: -50%;
}

.-help-menu-dropdown-show {
  opacity: 1;
  visibility: visible;
  top: 95%;
  right: -50%;
}
.-mobile-dropdown-show {
  opacity: 1;
  visibility: visible;
  top: 95%;
  right: -55%;
}
.-colorSupportingWhite icon svg > * {
  fill: $colorSupportingWhite;
}

@media (max-width: $breakpoint-medium-mobile-max) {
  app-functional-nav {
    .icon-nav {
      &.-container {
        &.-user-help {
          margin-right: spacer(3);
        }
      }
    }
  }
}
@media (max-width: $screen-mobile-large-max) {
  app-functional-nav {
    .-solution-finder {
      display: none !important;
    }
    .-search-box {
      display: none !important;
    }
    .-theme-icon {
      display: none !important;
    }
  }
}

@media (min-width: $screen-mobile-large-max) {
  app-functional-nav {
    .-theme-icon {
      margin-right: 5px;
      margin-top: 14px;
    }
    .-sun-light {
      margin-top: 5px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  app-functional-nav {
    .icon-nav {
      &.-container {
        width: 100%;

        &.-item {
          width: unset !important;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  app-functional-nav {
    .icon-nav {
      button-icon {
        icon,
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
